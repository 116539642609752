"use client";

// eslint-disable-next-line no-restricted-imports
import NextLink, { type LinkProps } from "next/link";

import { useIsBlocked } from "./navigation-block";
import { Translation } from "./translations/component";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";

export type { LinkProps };

export function Link({
  href,
  children,
  replace,
  ...rest
}: Parameters<typeof NextLink>[0]) {
  const isBlocked = useIsBlocked();

  if (isBlocked) {
    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <button
            className={rest.className}
            role="link"
            aria-haspopup="dialog"
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            aria-label={`Navigate to ${typeof children === "string" ? children : href.toString()}`}
          >
            {children}
          </button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              <Translation id="common.are-you-sure">Är du säker?</Translation>
            </AlertDialogTitle>
            <AlertDialogDescription>
              <Translation id="link-blocker.description">
                Om du navigerar bort från denna sida kommer du att förlora all
                osparad data.
              </Translation>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel data-testid="link-blocker-cancel">
              <Translation id="link-blocker.cancel">Stanna kvar</Translation>
            </AlertDialogCancel>
            <AlertDialogAction
              asChild
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90 shadow-sm"
              data-testid="link-blocker-confirm"
            >
              <NextLink href={href} replace={replace} {...rest}>
                <Translation id="link-blocker.confirm">
                  Kasta ändringarna och fortsätt
                </Translation>
              </NextLink>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return (
    <NextLink href={href} replace={replace} {...rest}>
      {children}
    </NextLink>
  );
}
