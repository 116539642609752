import { ChevronLeft } from "lucide-react";

import { Link } from "~/components/link";
import { cn } from "~/lib/utils";

import { buttonVariants } from "./ui/button";

export const BackChevron = ({
  href,
  text = "Tillbaka",
  onClick,
}: {
  href?: string;
  text?: React.ReactNode;
  onClick?: () => void;
}) => {
  if (href) {
    return (
      <Link
        href={href}
        className={cn(
          buttonVariants({ variant: "ghost" }),
          "left-8 mb-4 mt-4 md:left-16 md:mb-8 md:mt-8",
        )}
      >
        <>
          <ChevronLeft className="mr-2 h-4 w-4" />
          {text}
        </>
      </Link>
    );
  }
  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={cn(
          buttonVariants({ variant: "ghost" }),
          "left-8 mb-4 mt-4 md:left-16 md:mb-8 md:mt-8",
        )}
      >
        <>
          <ChevronLeft className="mr-2 h-4 w-4" />
          {text}
        </>
      </button>
    );
  }
  return <p>No href or onClick</p>;
};
