"use client";

import { useIntersectionObserver } from "@uidotdev/usehooks";
import Image from "next/image";
import { useEffect, useRef } from "react";

import { env } from "~/env.mjs";
import { cn } from "~/lib/utils";

interface Props {
  className?: string;
  src: string;
  width?: number;
  height?: number;
}

export function VideoEmbed({ className, src, width, height }: Props) {
  const [containerRef, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "50px",
  });

  const { videoUrl, thumbnailUrl } = getVideoUrlAndThumbnailUrl(src);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (entry?.isIntersecting) {
      void videoRef.current?.play().catch(() => {
        // prevent lint error
      });
    } else {
      videoRef.current?.pause();
    }
  }, [entry]);

  return (
    <div
      ref={containerRef}
      className={cn(className, "relative overflow-hidden")}
    >
      {thumbnailUrl && (
        <Image
          src={thumbnailUrl}
          alt="Video thumbnail"
          width={width ?? 600}
          height={height ?? 400}
          className="absolute"
        />
      )}
      <video
        ref={videoRef}
        src={videoUrl}
        loop
        preload="none"
        muted
        playsInline
        className="absolute"
      />
    </div>
  );
}

const cdnUrl = "https://cdn.nextgenclassroom.se";

function getVideoUrlAndThumbnailUrl(src: string) {
  if (src.startsWith(env.NEXT_PUBLIC_PAYLOAD_URL)) {
    return {
      videoUrl: src.replace(
        env.NEXT_PUBLIC_PAYLOAD_URL,
        `${cdnUrl}/static/cms`,
      ),
      // current result: https://cdn.nextgenclassroom.se/static/cms/video-thumbnail?width=640/media/chatbot.mp4
      thumbnailUrl: `${src.replace(
        env.NEXT_PUBLIC_PAYLOAD_URL,
        `${cdnUrl}/static/cms/video-thumbnail`,
      )}?width=640`,
    };
  }
  if (src.startsWith("/")) {
    return {
      videoUrl: `${cdnUrl}${src}`,
      thumbnailUrl: `${cdnUrl}/static/video-thumbnail${src}?width=640`,
    };
  }
  return {
    videoUrl: src,
    thumbnailUrl: undefined,
  };
}
