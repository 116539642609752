import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { ArrowBigUpIcon } from "lucide-react";
import { useEffect, useState } from "react";

import { Button } from "~/components/ui/button";
import { cn } from "~/lib/utils";

interface Props {
  specialChars?: string;
}

export function SpecialCharsToolbarPlugin({ specialChars }: Props) {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [shift, setShift] = useState(false);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
    );
  }, [activeEditor, editor]);

  if (!specialChars || specialChars.length < 1) {
    return null;
  }

  return (
    <div className="flex flex-wrap space-x-2 border-b border-gray-200 p-2 dark:border-gray-800">
      <Button
        type="button"
        className={cn("text-gray-600 dark:text-gray-400", {
          "bg-accent text-accent-foreground": shift,
        })}
        variant="ghost"
        size="icon"
        onClick={() => {
          setShift((s) => !s);
        }}
      >
        <span className="sr-only">Undo</span>
        <ArrowBigUpIcon className="h-4 w-4" />
      </Button>
      <div className="my-auto h-6 border-l border-gray-200 dark:border-gray-800" />
      {specialChars.split("").map((c) => (
        <Button
          key={c}
          type="button"
          className="text-gray-600 dark:text-gray-400"
          variant="ghost"
          size="icon"
          disabled={!isEditable}
          onClick={() => {
            const char = shift ? c.toUpperCase() : c;
            activeEditor.update(() => {
              const selection = $getSelection();
              if ($isRangeSelection(selection)) {
                selection.insertText(char);
              }
            });
            setShift(false);
          }}
        >
          {shift ? c.toUpperCase() : c}
        </Button>
      ))}
    </div>
  );
}
