"use client";

import { ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import { toast } from "sonner";
import { type z } from "zod";
import { useStore } from "zustand";

import { Link } from "~/components/link";
import { Translation } from "~/components/translations/component";
import { useTranslation } from "~/components/translations/provider";
import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { ResultSchema } from "~/lib/baseTaskSettings";
import { saveTaskInstanceResult } from "~/server/actions/tasks";
import { type WordTaskInsertSchema } from "~/server/zod/tasks";

import { QuizStep } from "./QuizStep";
import { SpellStep } from "./SpellStep";
import { type ViewName, useWordTaskStore } from "./provider";

export type Word = z.infer<typeof WordTaskInsertSchema>["words"][0];

export function DoneStep({
  groupId,
  taskInstanceId,
}: {
  groupId: number;
  taskInstanceId: string;
}) {
  const [loading, setLoading] = useState(false);

  const Store = useWordTaskStore();

  const { setView, view, answers, preview } = useStore(Store, (s) => ({
    setView: s.setView,
    view: s.view,
    answers: s.answers,
    preview: s.preview,
  }));

  const genericErrorDescription = useTranslation("common.something-went-wrong");

  async function handleSubmit() {
    if (preview) {
      return;
    }
    setLoading(true);
    const res = ResultSchema.safeParse({
      type: "WORD",
      view,
      answers: Object.values(answers),
    });
    if (!res.success) {
      toast.error(genericErrorDescription);
      setLoading(false);
      return;
    }
    await saveTaskInstanceResult({
      summary: res.data,
      taskInstanceId,
      groupId,
    });
    setLoading(false);
  }

  useEffect(() => {
    void handleSubmit();
  }, []);

  if (loading) {
    return (
      <div className="text-center">
        <h2 className="py-2 text-2xl font-bold">
          <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.step.sending">
            Skickar in svar...
          </Translation>
        </h2>
      </div>
    );
  }

  const res = ResultSchema.safeParse({
    type: "WORD",
    view,
    answers: Object.values(answers),
  });

  if (!res.success) {
    return (
      <div className="text-center">
        <h2 className="py-2 text-2xl font-bold">
          <Translation id="common.something-went-wrong">
            Något gick fel
          </Translation>
        </h2>
      </div>
    );
  }

  const score = (function () {
    if (res.data.type !== "WORD") {
      return 0;
    }
    switch (res.data.view) {
      case "QUIZ":
        const onFirstTry = res.data.answers.filter(
          (a) => a.data.errors === 0,
        ).length;
        return (
          <div>
            <p>
              <Translation
                id="app.elev.groupid.uppgift.taskinstanceid.task-word.step.score.quiz"
                replace={{ onFirstTry, total: res.data.answers.length }}
              >
                {"Du klarade {onFirstTry} av {total} ord på första försöket."}
              </Translation>
            </p>
          </div>
        );
      case "SPELL":
        const average =
          res.data.answers.reduce(
            (acc, curr) => acc + Number(curr.data.percentage ?? 0),
            0,
          ) / res.data.answers.length;
        const wasShown = res.data.answers.filter((a) => a.data.wasShown).length;
        return (
          <div>
            <p>
              <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.step.score.your-average">
                Ditt snittresultat
              </Translation>
              : {Math.min(Math.round((average / wasShown) * 100), 100)}%
            </p>
            <p>
              <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.step.score.you-displayed-answers-prefix">
                Du visade svaren till
              </Translation>{" "}
              {wasShown}{" "}
              <Translation id="app.elev.groupid.uppgift.taskinstanceid.task-word.step.score.you-displayed-answers-suffix">
                ord
              </Translation>
              .
            </p>
          </div>
        );
      default:
        return 0;
    }
  })();

  return (
    <div className="container">
      <div className="text-center">
        {score}
        <div className="space-x-1 pt-4">
          <Button
            onClick={() => {
              setView(undefined);
            }}
            variant="secondary"
            size="sm"
          >
            <Translation id="common.start-over">Börja om</Translation>
          </Button>
          <Link href={preview ? "#" : `/elev/${groupId}`}>
            <Button variant="default" size="sm" disabled={preview}>
              <Translation id="common.back-to-group">
                Tillbaka till gruppen
              </Translation>{" "}
              <ChevronRight />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export function Step({ word, viewName }: { word: Word; viewName: ViewName }) {
  const Store = useWordTaskStore();
  const task = useStore(Store, (s) => s.computed.task);
  const { activeStep, stepCount } = useWizard();

  return (
    <div className="w-full">
      <div className="text-center">
        <Badge variant="secondary">{task.leftTitle}</Badge>
        <h2 className="mx-auto max-w-3xl whitespace-break-spaces py-2 text-2xl font-bold">
          {word.left}
        </h2>
      </div>
      {viewName === "QUIZ" && <QuizStep word={word} />}
      {viewName === "SPELL" && <SpellStep word={word} />}
      <div className="container mt-4 max-w-[400px] text-center">
        <Translation
          id="app.elev.groupid.uppgift.taskinstanceid.task-word.step.step-count"
          replace={{ activeStep, stepCount: stepCount - 1 }}
        >
          {"Ord {activeStep} av {stepCount}"}
        </Translation>
      </div>
    </div>
  );
}
