import { env } from "./env.mjs";

const cdnUrl = "https://cdn.nextgenclassroom.se";

export default function imageLoader({
  src,
  width,
}: {
  src: string;
  width: number;
  quality?: number;
}) {
  // NextJS images
  if (process.env.NODE_ENV === "production" && src.startsWith("/")) {
    return `${cdnUrl}${src}?width=${width}`;
  }
  // CMS images
  if (src.startsWith(env.NEXT_PUBLIC_PAYLOAD_URL)) {
    // We proxy CMS images through NextJS to save some cost on Bunny Optimizer (paid per domain)
    return `${src}?width=${width}`.replace(
      env.NEXT_PUBLIC_PAYLOAD_URL,
      `${cdnUrl}/static/cms`,
    );
  }
  return src;
}
