import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createHeadingNode, $isHeadingNode } from "@lexical/rich-text";
import type { HeadingTagType } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  type LexicalEditor,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import {
  ALargeSmallIcon,
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  ChevronDownIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  Heading5Icon,
  ItalicIcon,
  ListIcon,
  ListOrderedIcon,
  RedoIcon,
  TextIcon,
  UnderlineIcon,
  UndoIcon,
} from "lucide-react";
import { useCallback, useEffect, useState } from "react";

import { Button } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";

const blockTypeToBlockName = {
  bullet: "Bulleted List",
  check: "Check List",
  code: "Code Block",
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4",
  h5: "Heading 5",
  h6: "Heading 6",
  number: "Numbered List",
  paragraph: "Normal",
  quote: "Quote",
};

function ListFormat({
  editor,
  blockType,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  editor: LexicalEditor;
}): JSX.Element {
  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  };

  return (
    <>
      <Button
        type="button"
        className="text-gray-600 dark:text-gray-400"
        variant="ghost"
        size="icon"
        onClick={formatBulletList}
      >
        <span className="sr-only">Bulleted List</span>
        <ListIcon className="h-4 w-4" />
      </Button>
      <Button
        type="button"
        className="text-gray-600 dark:text-gray-400"
        variant="ghost"
        size="icon"
        onClick={formatNumberedList}
      >
        <span className="sr-only">Numbered List</span>
        <ListOrderedIcon className="h-4 w-4" />
      </Button>
    </>
  );
}

function HeadingFormat({
  editor,
  blockType,
}: {
  blockType: keyof typeof blockTypeToBlockName;
  editor: LexicalEditor;
}): JSX.Element {
  const formatParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const formatHeading = (headingSize: HeadingTagType) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode(headingSize));
        }
      });
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          type="button"
          className="text-gray-600 dark:text-gray-400"
          variant="ghost"
        >
          <ALargeSmallIcon className="mr-2 h-4 w-4" />
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="text-xs">
        <DropdownMenuItem onClick={() => formatHeading("h1")}>
          <Heading1Icon className="mr-2 h-4 w-4" />
          <span className="text-xs">Heading 1</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => formatHeading("h2")}>
          <Heading2Icon className="mr-2 h-4 w-4" />
          <span className="text-xs">Heading 2</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => formatHeading("h3")}>
          <Heading3Icon className="mr-2 h-4 w-4" />
          <span className="text-xs">Heading 3</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => formatHeading("h4")}>
          <Heading4Icon className="mr-2 h-4 w-4" />
          <span className="text-xs">Heading 4</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => formatHeading("h5")}>
          <Heading5Icon className="mr-2 h-4 w-4" />
          <span className="text-xs">Heading 5</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => formatParagraph()}>
          <TextIcon className="mr-2 h-4 w-4" />
          <span className="text-xs">Normal</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function ToolbarPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>("paragraph");
  const [canUndo, setCanUndo] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          );
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName);
          }
        }
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    );
  }, [activeEditor, editor, updateToolbar]);

  return (
    <div className="flex flex-wrap space-x-2 border-b border-gray-200 p-2 dark:border-gray-800">
      <HeadingFormat blockType={blockType} editor={editor} />
      <div className="my-auto h-6 border-l border-gray-200 dark:border-gray-800" />
      <Button
        type="button"
        className="text-gray-600 dark:text-gray-400"
        variant="ghost"
        size="icon"
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        }}
      >
        <span className="sr-only">Bold</span>
        <BoldIcon className="h-4 w-4" />
      </Button>
      <Button
        type="button"
        className="text-gray-600 dark:text-gray-400"
        variant="ghost"
        size="icon"
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        }}
      >
        <span className="sr-only">Italic</span>
        <ItalicIcon className="h-4 w-4" />
      </Button>
      <Button
        type="button"
        className="text-gray-600 dark:text-gray-400"
        variant="ghost"
        size="icon"
        onClick={() => {
          activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        }}
      >
        <span className="sr-only">Underline</span>
        <UnderlineIcon className="h-4 w-4" />
      </Button>
      <div className="my-auto h-6 border-l border-gray-200 dark:border-gray-800" />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            type="button"
            className="text-gray-600 dark:text-gray-400"
            variant="ghost"
          >
            <span className="sr-only">Alignment</span>
            <AlignLeftIcon className="h-4 w-4" />
            <ChevronDownIcon className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
            }}
          >
            <AlignLeftIcon className="mr-2 h-4 w-4" />
            <span className="text-xs">Left align</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
            }}
          >
            <AlignCenterIcon className="mr-2 h-4 w-4" />
            <span className="text-xs">Center align</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
            }}
          >
            <AlignRightIcon className="mr-2 h-4 w-4" />
            <span className="text-xs">Right align</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
            }}
          >
            <AlignJustifyIcon className="mr-2 h-4 w-4" />
            <span className="text-xs">Justify</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <div className="my-auto h-6 border-l border-gray-200 dark:border-gray-800" />
      <ListFormat blockType={blockType} editor={editor} />
      <div className="my-auto h-6 border-l border-gray-200 dark:border-gray-800" />
      <Button
        type="button"
        className="text-gray-600 dark:text-gray-400"
        variant="ghost"
        size="icon"
        disabled={!canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
      >
        <span className="sr-only">Undo</span>
        <UndoIcon className="h-4 w-4" />
      </Button>
      <Button
        type="button"
        className="text-gray-600 dark:text-gray-400"
        variant="ghost"
        size="icon"
        disabled={!canUndo || !isEditable}
        onClick={() => {
          activeEditor.dispatchCommand(REDO_COMMAND, undefined);
        }}
      >
        <span className="sr-only">Redo</span>
        <RedoIcon className="h-4 w-4" />
      </Button>
    </div>
  );
}
